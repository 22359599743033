<template>
  <div class="box">
    <list :api="api" type="lawyer" :title="$t('f4ebe52')" />
  </div>
</template>

<script>
import api from "@/api/communication/lawyer";
import List from "@/views/communication/components/list/list";
export default {
  name: "Forum",
  components: {
    List,
  },
  data() {
    return {
      api,
    };
  },
};
</script>
