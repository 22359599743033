<template>
  <div class="container">
    <only-text v-if="type==='onlyText'" v-bind="$props" />
    <lawyer v-else-if="type==='lawyer'" v-bind="$props" />
    <experts v-else-if="type==='experts'" v-bind="$props" />
    <activity v-else-if="type==='activity'" v-bind="$props" />
  </div>
</template>

<script>
import OnlyText from "./onlyText.vue"
import Activity from "./activity.vue"
import Experts from "./experts.vue"
import lawyer from "./lawyer.vue"
export default {
  components: {
    OnlyText,
    lawyer,
    Experts,
    Activity
  },
  props: {
    type: {
      type: String,
      default: ''
    },
    data: {
      type: Array,
      default: () => []
    },
    path: {
      type: String,
      default: ''
    },
    pageInfo: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
