<template>
  <div class="box">
    <list
      :api="api"
      type="onlyText"
      :need-country-id="needCountryId"
      path="ask"
      :title="$t('d851bf0')"
    />
  </div>
</template>

<script>
import api from "@/api/communication/ask";
import List from "@/views/communication/components/list/list";
export default {
  name: "Article",
  components: {
    List,
  },
  data() {
    return {
      api,
      needCountryId: true,
    };
  },
};
</script>

