<template>
  <div class="list">
    <div v-for="(item, index) in data" :key="index" class="list__item list__item--experts" @click="jumpDetail(item.id)">
      <div class="image">
        <img :src="item.imgUrl" :alt="item.imgUrl" />
      </div>
      <div class="text">
        <div class="title-wrapper">
          <div class="title">{{ item.title }}</div>
          <span class="date">{{ item.issueDate | date('YYYY-MM-DD') }}</span>
        </div>
        <div class="desc">{{ item.content }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListItem',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    path: {
      type: String,
      default: ''
    },
    pageInfo: {
      type: Object,
      default: () => {}
    },
  },
  methods: {
    jumpDetail(id) {
      this.$router.push({
        path: `/communication/${this.path}/detail?id=${id}&page=${this.pageInfo.page}`
      })
    }
  }
}
</script>

<style scoped lang="less">
@import "../../styles.less";
.list {
  .listContainer();
  .listItemExperts();
}
</style>
