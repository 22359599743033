<template>
  <div class="box">
    <list :api="api" type="experts" path="experts" :title="$t('6412242')" />
  </div>
</template>

<script>
import api from "@/api/communication/experts"
import List from "@/views/communication/components/list/list"
export default {
  name: "Forum",
  components: {
    List
  },
  data() {
    return {
      api
    }
  },
}
</script>
