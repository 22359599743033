<template>
  <div class="box">
    <list :api="api" type="onlyText" :title="$t('d29b5da')" path="forum" />
  </div>
</template>

<script>
import api from "@/api/communication/forum"
import List from "@/views/communication/components/list/list"

export default {
  name: "Forum",
  components: {
    List,
  },
  data() {
    return {
      api
    }
  },
}
</script>

