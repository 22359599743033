<template>
  <Card
    v-loading="loading"
    shadow="never"
    :body-style="{ border: '0', height: '100%' }"
  >
    <template v-slot:header>{{ title }}</template>
    <template v-slot:actions>
      <el-input v-model="keyword" :placeholder="$t('41b6e5b')" class="search">
        <el-button
          slot="append"
          type="primary"
          icon="el-icon-search"
          @click="search"
          >{{ $t("3a3636a") }}</el-button
        >
      </el-input>
    </template>
    <country-tag v-if="needCountry" @select="handleSelect" />
    <country-id-tag v-if="needCountryId" @select="handleSelect" />
    <lawyer-tag v-if="type === 'lawyer'" @select="handleSelect" />
    <Empty v-if="data.length === 0" />
    <list-item v-bind="$props" :page-info="pageInfo" :data="data" />
    <Pagination :page-info="pageInfo" @changePage="changePage" />
  </Card>
</template>

<script>
import Pagination from "@/components/Pagination";
import Card from "@/components/Card";
import ListItem from "@/views/communication/components/listItem";
import CountryTag from "./country";
import CountryIdTag from "./countryId";
import LawyerTag from "./lawyerTag";
import ListMixin from "@/views/communication/listMixin";
import Empty from "@/components/Empty/Factory";

export default {
  name: "List",
  components: {
    Pagination,
    Card,
    ListItem,
    CountryTag,
    CountryIdTag,
    LawyerTag,
    Empty,
  },
  mixins: [ListMixin],
  props: {
    type: {
      type: String,
      default: "",
    },
    path: {
      type: String,
      default: "",
    },
    api: undefined,
    title: {
      type: String,
      default: "",
    },
    needCountry: {
      type: Boolean,
      default: false,
    },
    needCountryId: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    search() {
      this.pageInfo.page = 1;
      this.getList();
    },
  },
};
</script>
<style scoped lang="less">
.search {
  width: 280px;
  /deep/ .el-button--primary {
    color: #fff;
    background-color: #00a4ffff !important;
  }
}
.list-container {
  padding: 0 10px;
  margin-bottom: 16px;
  .list-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 29px 0;
    color: #333333;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.09);
    cursor: pointer;
    .title {
      font-size: 16px;
      font-family: MicrosoftYaHei;

      line-height: 28px;
    }
    .date {
      margin-left: 10px;
      text-align: right;
      font-size: 20px;
      color: #666666;
      line-height: 28px;
      width: 100px;
      span {
        display: block;
      }
      span.sub {
        font-size: 14px;
        line-height: 14px;
        margin-top: 10px;
      }
    }
  }
  .list-item:hover {
    color: rgba(0, 164, 255, 1);
    .date span {
      color: rgba(0, 164, 255, 1);
    }
  }
}
</style>
