<template>
  <div class="box">
    <Empty v-if="data.length === 0"/>
    <list-item :data="data" type="onlyText" path="notice" :page-info="pageInfo" />
    <Pagination :page-info="pageInfo" @changePage="changePage" />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination"
import api from "@/api/communication/notice"
import ListItem from "@/views/communication/components/listItem"
import ListMixin from "@/views/communication/listMixin"
import Empty from "@/components/Empty/Factory"

export default {
  name: "Forum",
  components: {
    ListItem,
    Pagination,
    Empty,
  },
  mixins: [ListMixin],
  data() {
    return {
      api
    }
  },
  methods: {
    handleSelect(id) {
      this.countryId = id
      this.pageInfo.currentPage = 1
      this.getList({ country: id })
    },
  }
}
</script>
