<template>
  <div class="box">
    <list :api="api" type="onlyText" path="case" :title="$t('3692525')" />
  </div>
</template>

<script>
import api from "@/api/communication/case"
import List from "@/views/communication/components/list/list"
export default {
  name: "Article",
  components: {
    List
  },
  data() {
    return {
      api
    }
  },
}
</script>
