<template>
  <div class="container">
    <div v-for="item in data" :key="item.serviceNo" class="edu-item">
      <div
        class="cover"
        :style="{ backgroundImage: `url('${item.picture}')` }"
      ></div>
      <div class="cont">
        <div class="title">
          <h5>
            {{ item.title | textFilter }}
          </h5>
          <span class="tag" :class="tagCls[item.status]">{{
            item.status | textFilter
          }}</span>
        </div>
        <ul class="desc">
          <li>
            <i class="el-icon-time"></i>
            <span>{{ $t('c92c53c') }}：{{ item.date }} {{ item.startTime }}-{{
              item.endTime
            }}</span>
          </li>
          <li>
            <i class="el-icon-location-outline"></i>
            <span>{{ $t('15f5be0') }}：{{ item.address | textFilter }}</span>
          </li>
          <li>
            <i class="el-icon-user"></i>
            <span>{{ $t('6b310d6') }}：{{ item.organizer | textFilter }}</span>
          </li>
        </ul>
        <el-button class="btn" @click="jumpDetail(item.id)">{{ $t('ad6ab8a') }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    path: {
      type: String,
      default: ''
    },
    pageInfo: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      tagCls: {
        已结束: 'tag-after',
        未开始: 'tag-before',
        进行中: 'tag-doing',
      }
    }
  },
  methods: {
    jumpDetail(id) {
      this.$router.push({
        path: `/communication/${this.path}/detail?id=${id}`
      })
    }
  }
}
</script>

<style lang="less" scoped>
.edu-item {
  display: flex;
  padding: 30px 0;
  border-bottom: 1px dashed #e8e8e8;

  &:first-child {
    padding-top: 10px;
  }

  .cover {
    width: 320px;
    height: 200px;
    margin-right: 30px;
    border-radius: 4px;
    background: #efefef no-repeat center;
    background-size: cover;
  }

  .cont {
    position: relative;
    min-width: 0;
    flex: 1;
  }

  .title {
    height: 70px;
    display: flex;
    align-items: center;

    h5 {
      font-size: 18px;
      line-height: 32px;
      color: #333333;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .tag {
      display: block;
      text-align: center;
      margin-left: 8px;
      min-width: 100px;
      padding: 0 10px;
      height: 32px;
      line-height: 32px;
      color: #999999;
      background: #ededed;
      font-weight: bold;

      &-doing {
        color: #27c360;
        background-color: #e4f8eb;
      }

      &-after {
        color: #999999;
        background: #ededed;
      }

      &-before {
        color: #faad14;
        background: #fef2d9;
      }
    }
  }

  .desc {
    font-size: 14px;
    color: #666666;
    line-height: 24px;

    i {
      font-size: 16px;
      margin-right: 10px;
      color: #c3c3c3;
    }

    li {
      padding-bottom: 16px;
    }
  }

  .btn {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    min-width: 120px;
    height: 40px;
    border-color: #00a4ff;
    color: #00a4ff;
  }
}
</style>
