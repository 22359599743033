<template>
  <el-card
    shadow="never"
  >
    <div class="tab-container">
      <el-tabs v-model="activeName" @tab-click="tabClick">
        <el-tab-pane :label="autoPrefix('0165560')" name="policy">
          <policy ref="policy" />
        </el-tab-pane>
        <el-tab-pane :label="autoPrefix('a2374c4')" name="news">
          <news ref="news" />
        </el-tab-pane>
      </el-tabs>
      <el-input v-model="keyword" :placeholder="autoPrefix('41b6e5b')" class="search">
        <el-button slot="append" type="primary" icon="el-icon-search" @click="search">{{ $t('3a3636a') }}</el-button>
      </el-input>
    </div>
  </el-card>
</template>

<script>
import News from './news'
import Policy from './policy'

export default {
  name: "List",
  components: {
    News,
    Policy
  },
  data() {
    return {
      keyword: '',
      activeName: 'policy'
    }
  },
  created() {
    const path = this.$route.path
    const obj = {
      '/communication/news': 'news',
      '/communication/policy': 'policy',
    }
    this.activeName = obj[path]
  },
  methods: {
    tabClick(val) {
      this.$router.replace(this.activeName)
    },
    search() {
      this.$refs[this.activeName].pageInfo.page = 1
      this.$refs[this.activeName].getList({ keyword: this.keyword })
    },
    autoPrefix(name) {
      let fun = '$t'
      if (this.abroad) {
        fun = 'geEn'
      }
      return this[fun](name)
    },
  }
}
</script>

<style scoped lang="less">
@import "../styles.less";

.tab-container {
  .tabContainer()
}
</style>
