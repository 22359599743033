<template>
  <div class="container">
    <div v-for="item in data" :key="item.serviceNo" class="edu-item">
      <div
        class="cover"
        :style="{ backgroundImage: `url(${item.logoUrl})` }"
      ></div>
      <div class="cont">
        <h5 class="title">
          {{ item.name | textFilter }}
        </h5>
        <div class="buttons">
          <button class="btn btn-blue" @click="jump('detail', item.id)">查看详情</button>
          <button class="btn btn-empty" @click="jump('consult', item.id)">在线咨询</button>
        </div>
        <ul class="desc">
          <li>{{ item.province }}- {{ item.practiceOrganization }} </li>
          <li>从业年限：{{ item.yearsOfPractice }} 年</li>
          <li>擅长领域：{{ item.businessExpertise | textFilter }} </li>
          <li>已回复( {{ item.num }} )条法律问答</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    path: {
      type: String,
      default: ''
    }
  },
  methods: {
    jump(path, id) {
      const url = `${path}?id=${id}`
      this.$router.push({
        path: `/communication/lawyer/${url}`
      })
    }
  }
}
</script>

<style lang="less" scoped>
.edu-item {
  display: flex;
  padding: 30px;
  border: 1px solid #E0E1E5;
  margin-top: 30px;
  .cover {
    width: 160px;
    height: 160px;
    margin-right: 30px;
    border-radius: 4px;
    background: #efefef no-repeat center;
    background-size: cover;
  }

  .cont {
    position: relative;
    min-width: 0;
    flex: 1;
    h5 {
      font-size: 18px;
      line-height: 18px;
      color: #333333;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .btn {
      height: 26px;
      line-height: 26px;
      width: 80px;
      text-align: center;
      border-radius: 4px;
      margin: 12px 0;
      &-blue {
        background-color: #1890FF;
        color: #fff;
      }
      &:first-child {
        margin-right: 10px;
      }
      &-empty {
        color: #1890FF;
        border: 1px solid #1890FF;
        background-color: transparent;
      }
    }
    .desc {
      font-size: 14px;
      color: #8F8F8F;
      line-height: 24px;
      & > li {
        &:last-child {
          color: #1890FF;
        }
      }
    }
  }
}
</style>
