<template>
  <div class="box">
    <Empty v-if="data.length === 0" />
    <country-tag v-else @select="handleSelect" />
    <list-item
      :data="data"
      type="onlyText"
      path="policy"
      :page-info="pageInfo"
    />
    <Pagination :page-info="pageInfo" @changePage="changePage" />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import api from "@/api/communication/policy.js";
import CountryTag from "@/views/communication/components/list/country.vue";
import ListItem from "@/views/communication/components/listItem";
import ListMixin from "@/views/communication/listMixin";
import Empty from "@/components/Empty/Factory";

export default {
  name: "Forum",
  components: {
    Empty,
    ListItem,
    CountryTag,
    Pagination,
  },
  mixins: [ListMixin],
  data() {
    return {
      api,
    };
  },
};
</script>

