import { translateObj } from '@/utils/helper'
import API from '@/api'
export default {
  data() {
    return {
      loading: false,
      countryId: '',
      keyword: '',
      data: [],
      pageInfo: {
        page: 1,
        size: 15,
        total: 0,
      },
    }
  },
  mounted() {
    if (this.type === 'experts') {
      this.pageInfo.size = 6
    }
    this.getList()
  },
  methods: {
    handleSelect(keyword) {
      // 针对投资贸易问答，需要传递countryId单独做的调整
      if (typeof keyword === 'number') {
        this.countryId = keyword;
        this.getList({
          countryId: keyword
        })
      } else {
        this.keyword = keyword
        this.getList({
          keyword
        })
      }
      
    },
    changePage(page) {
      this.pageInfo.page = page
      this.getList()
    },
    async getList(params = {}) {
      this.loading = true
      const { pageInfo: { size, page }, api } = this
      let { keyword } = this
      const { appid, secretKey, salt } = translateObj
      if (keyword) {
        const sign = this.$md5(`${appid}${keyword}${salt}${secretKey}`)
        const to = 'zh'
        const from = 'auto'
        const url = `/baiduApi/api/trans/vip/translate?q=${keyword}&from=${from}&to=${to}&appid=${appid}&salt=${salt}&sign=${sign}`
        await API.common.getTranslateKeyword(url)
          .then(res => {
            keyword = res.trans_result[0].dst
          }).catch((err) => {
            console.log('err', err)
          })
      }

      api
        .get({
          size,
          page,
          keyword,
          ...params
        })
        .then((res) => {
          if (res) {
            this.data = Object.freeze(res.content)
            this.pageInfo.total = res.page.total
            this.loading = false
          }
        })
    },
  },
}
