<template>
  <el-card
    shadow="never"
    class="fix-border"
  >
    <div class="tab-container">
      <el-tabs v-model="activeName" @tab-click="tabClick">
        <el-tab-pane :label="autoPrefix('4f3d806')" name="notice">
          <notice ref="notice" />
        </el-tab-pane>
        <el-tab-pane :label="autoPrefix('5680a38')" name="activity">
          <activity ref="activity" />
        </el-tab-pane>
      </el-tabs>
      <el-input v-model="keyword" :placeholder="autoPrefix('41b6e5b')" class="search">
        <el-button slot="append" type="primary" icon="el-icon-search" @click="search">{{ $t('3a3636a') }}</el-button>
      </el-input>
    </div>
  </el-card>
</template>

<script>
import Notice from './notice'
import Activity from './activity'

export default {
  name: "List",
  components: {
    Notice,
    Activity
  },
  data() {
    return {
      keyword: '',
      activeName: 'notice'
    }
  },
  created() {
    const path = this.$route.path
    const obj = {
      '/communication/notice': 'notice',
      '/communication/activity': 'activity',
    }
    this.activeName = obj[path]
  },
  methods: {
    tabClick(val) {
      this.$router.replace(this.activeName)
    },
    search() {
      this.$refs[this.activeName].pageInfo.page = 1
      this.$refs[this.activeName].getList({ keyword: this.keyword })
    },
    autoPrefix(name) {
      let fun = '$t'
      if (this.abroad) {
        fun = 'geEn'
      }
      return this[fun](name)
    },
  }
}
</script>

<style scoped lang="less">
@import "../styles.less";
.tab-container {
  .tabContainer()
}
.fix-border {
  border: 0;
}
</style>
