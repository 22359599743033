<template>
  <div class="box">
    <Empty v-if="data.length === 0" />
    <list-item :data="data" type="onlyText" path="news" :page-info="pageInfo" />
    <Pagination :page-info="pageInfo" @changePage="changePage" />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination"
import api from "@/api/communication/news.js"
import ListItem from "@/views/communication/components/listItem"
import ListMixin from "@/views/communication/listMixin"
import Empty from "@/components/Empty/Factory"

export default {
  name: "Forum",
  components: {
    Empty,
    ListItem,
    Pagination
  },
  mixins: [ListMixin],
  data() {
    return {
      api
    }
  }
}
</script>
