<template>
  <div class="about content">
    <div class="inner">
      <LeftSidebar
        class="lt"
        :data="leftData"
        @setActiveComponent="setActiveComponent"
      />
      <div class="cont">
        <keep-alive>
          <component :is="activeComponent" />
        </keep-alive>
      </div>
    </div>
  </div>
</template>
<script>
import Case from "./case"
import Ask from "./ask"
import Forum from "./forum"
import Article from "./article"
import Experts from "./experts"
import Lawyer from "./lawyer"
import Policy from "./news/index.vue"
import Notice from "./notice"
import LeftSidebar from "@/components/LeftSidebar"
export default {
  name: "Communication",
  components: {
    LeftSidebar,
    Case,
    Ask,
    Forum,
    Article,
    Experts,
    Lawyer,
    Policy,
    Notice,
  },
  data() {
    return {
      activeComponent: "Policy",
      leftData: {
        icon: require("@/assets/about/icon.png"),
        title: "bae89f7",
        list: [
          {
            event: "setActiveComponent",
            name: "de9d02f",
            componentName: "Policy",
            path: ["/communication/policy", "/communication/news"],
          },
          {
            event: "setActiveComponent",
            name: "3692525",
            componentName: "Case",
            path: "/communication/case",
          },
          {
            event: "setActiveComponent",
            componentName: "Article",
            name: "cc66d3f",
            path: "/communication/article",
          },
          {
            event: "setActiveComponent",
            name: "6412242",
            componentName: "Experts",
            path: "/communication/experts",
          },
          {
            event: "setActiveComponent",
            componentName: "Forum",
            name: "d29b5da",
            path: "/communication/forum",
          },
          {
            event: "setActiveComponent",
            componentName: "Notice",
            name: "4f3d806",
            path: ["/communication/notice", "/communication/activity"],
          },
          {
            event: "setActiveComponent",
            componentName: "Ask",
            name: "d851bf0",
            path: "/communication/ask",
          },
          {
            event: "setActiveComponent",
            name: "f4ebe52",
            componentName: "Lawyer",
            path: "/communication/lawyer",
          },
        ],
      },
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newValue) {
        this.handleActiveComponent();
      },
    },
  },
  created() {},
  methods: {
    handleActiveComponent() {
      const obj = {
        "/communication/policy": "Policy",
        "/communication/news": "Policy",
        "/communication/case": "Case",
        "/communication/article": "Article",
        "/communication/experts": "Experts",
        "/communication/forum": "Forum",
        "/communication/notice": "Notice",
        "/communication/activity": "Notice",
        "/communication/ask": "Ask",
        "/communication/lawyer": "Lawyer",
      };
      this.activeComponent = obj[this.$route.path];
    },
    // 暂时不用, 用leftData的path跳转
    setActiveComponent(val, item, index) {
      const componentName = this.leftData.list[index].componentName;
      // console.log('componentName', componentName)
      this.activeComponent = componentName;
      this.$router.push(`/communication/${componentName.toLocaleLowerCase()}`);
    },
  },
};
</script>

<style scoped lang="less">
@import "~@/styles/variables.less";
.content {
  width: @uni-width;
  margin: 20px auto;
}
.inner {
  display: flex;
  justify-content: space-between;
  padding: 0 0 20px;
}

.side {
  width: 260px;
  margin-right: 20px;
  background: #fff;
  box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.08);
}

.cont {
  width: 920px;
  background: #fff;
  box-shadow: 0px 2px 22px 0px rgba(142, 142, 142, 0.18);
}
</style>
