<template>
  <div class="tag-container fix-space">
    涉外法律：
    <span class="tag-list">
      <span
        :class="`tag ${selected === '' ? 'active' : ''}`"
        @click="handleTagClick('')"
      >
        全部
      </span>
      <span
        v-for="(item, key) in types"
        :key="key"
        :class="`tag ${selected === item ? 'active' : ''}`"
        @click="handleTagClick(item)"
      >
        {{ item }}
      </span>
    </span>
  </div>
</template>

<script>
export default {
  name: "LawyerTag",
  data() {
    return {
      selected: "",
      types: ['海事海商', '招商引资', '外商投资', '合资合作', 'WTO事务', '倾销补贴', '涉外仲裁']
    }
  },
  methods: {
    handleTagClick(item) {
      this.selected = item
      this.$emit("select", item)
    },
  }
}
</script>

<style scoped lang="less">
@import "../../styles.less";
.tag-container {
  .tagSearch()
}
.fix-space {
  padding-top: 0px;
}
</style>
