<template>
  <div class="box">
    <Empty v-if="data.length === 0" />
    <list-item :data="data" type="activity" path="activity" />
    <Pagination :page-info="pageInfo" @changePage="changePage" />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination"
import api from "@/api/communication/activity"
import CountryTag from "@/views/communication/components/list/country.vue"
import ListItem from "@/views/communication/components/listItem"
import ListMixin from "@/views/communication/listMixin"
import Empty from "@/components/Empty/Factory"

export default {
  name: "Forum",
  components: {
    ListItem,
    CountryTag,
    Pagination,
    Empty
  },
  mixins: [ListMixin],
  data() {
    return {
      api,
      pageInfo: {
        page: 1,
        size: 6,
        total: 0,
      },
    }
  }
}
</script>

