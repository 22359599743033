<template>
  <div class="box">
    <list :api="api" type="onlyText" path="article" :title="$t('cc66d3f')" />
  </div>
</template>

<script>
import api from "@/api/communication/article"
import List from "@/views/communication/components/list/list"
export default {
  name: "Article",
  components: {
    List,
  },
  data() {
    return {
      api
    }
  },
}
</script>

