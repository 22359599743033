<template>
  <div class="tag-container fix-space">
    {{ $t("47fef72") }}：
    <span class="tag-list">
      <span
        :class="`tag ${selected === '' ? 'active' : ''}`"
        @click="handleTagClick('')"
      >
        全部
      </span>
      <span
        v-for="item in countries"
        :key="item.id"
        :class="`tag ${selected === item.id ? 'active' : ''}`"
        @click="handleTagClick(item.id)"
      >
        {{ item.name }}
      </span>
    </span>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "CountryIdTag",
  data() {
    return {
      countryList: [],
      selected: "",
    };
  },
  computed: {
    ...mapGetters(["dictionaries"]),
    countries() {
      const list = this.dictionaries["COUNTRY"]
        ? this.dictionaries["COUNTRY"].list
        : [];
      return list.filter((item) => {
        return item.id !== 1;
      });
    },
  },
  mounted() {
    this.getDictionary({ type: "COUNTRY" });
  },
  updated() {
    console.log(this.dictionaries["COUNTRY"]);
  },
  methods: {
    ...mapActions(["getDictionary"]),
    handleTagClick(id) {
      this.selected = id;
      this.$emit("select", id);
    },
  },
};
</script>

<style scoped lang="less">
@import "../../styles.less";
.tag-container {
  .tagSearch();
}
.fix-space {
  margin-bottom: 20px;
}
</style>
